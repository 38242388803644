<template>
  <div class="app-header">
    <div class="app-header-space">
      <router-link :to="{ name: 'Home' }">
        <img src="@/assets/img/realsight-logo.svg" alt="Realsight">
      </router-link>
      <div class="app-header-navtoggle" role="button" @click="isNavVisible = !isNavVisible">
        Menu
      </div>
      <nav class="app-header-nav" :class="{ visible: isNavVisible }">
        <router-link :to="{ name: 'Explorer' }">Mapa</router-link>
        <router-link :to="{ name: 'Contact' }">Kontakt</router-link>
        <!--
        <router-link v-if="!$store.getters.isLoggedIn" :to="{ name: 'Login' }">Prihlásenie</router-link>
        <router-link v-else :to="{ name: 'Account' }">Môj účet</router-link>
        -->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  data: () => ({
    isNavVisible: false,
  }),
};
</script>

<style lang="scss" scoped>
.app-header-space {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.app-header-navtoggle {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.app-header-nav {
  display: none;
  a {
    display: inline-block;
    margin-left: 1rem;
    color: white;
    text-decoration: none;
  }
  &.visible {
    display: block;
    flex: 0 0 100%;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>
