import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import router from '@/router';

Sentry.init({
  Vue,
  dsn: 'https://7155d8a9ef784cdfb0cd0a24be5b69fc@o957993.ingest.sentry.io/5906893',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'realsight.sk', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});
