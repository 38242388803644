import Vue from 'vue';
import VTooltip from 'v-tooltip';
import Toasted from 'vue-toasted';
import VueTour from 'vue-tour';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/sentry';

Vue.use(VTooltip);
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 3000,
});
Vue.use(VueTour);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
