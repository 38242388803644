import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import jwtDecode from 'jwt-decode';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userToken: null,
    tourFinished: false,
  },
  mutations: {
    setUserToken(state, payload) {
      state.userToken = payload;
    },
    setTourFinished(state, payload) {
      state.tourFinished = payload;
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.userToken,
    userData: (state) => jwtDecode(state.userToken),
  },
  plugins: [
    createPersistedState(),
  ],
});

export default store;
