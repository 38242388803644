<template>
  <div class="app-footer">
    <ul class="list left">
      <li>&copy; 2021 Realsight</li>
      <li><a href="mailto:info@realsight.sk">info@realsight.sk</a></li>
    </ul>
    <ul class="list right">
      <li>Beta verzia @ 25.10.2021</li>
      <li><a href="#" role="button" @click="reset">Reset</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {
    reset() {
      this.$store.commit('setUserToken', null);
      this.$store.commit('setTourFinished', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-footer {
  padding: 1.25rem 1rem;
  color: $gray-500;
  text-align: center;
  .list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
    }
    li + li {
      padding-left: 1rem;
      border-left: 1px solid $gray-500;
      margin-left: 1rem;
    }
    a {
      color: $gray-500;
    }
  }
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}
</style>
