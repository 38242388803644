<template>
  <div class="app">
    <AppHeader/>
    <router-view
      class="app-main"
    />
    <AppFooter/>
  </div>
</template>

<script>
import 'vue-tour/dist/vue-tour.css';
import '@/styles/main.scss';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'Realsight',
  components: {
    AppHeader,
    AppFooter,
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      this.$store.commit('setUserToken', token);
    }
  },
};
</script>
